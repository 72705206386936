import { styled } from '@/stitches/index.js';

export const NotFoundContainer = styled('main', {
  alignItems: 'center',
  backgroundColor: '$brandWhite',
  backgroundImage: 'url(/images/not-found-404-background.webp)',
  backgroundPosition: 'bottom center',
  backgroundSize: 'cover',
  display: 'flex',
  fontFamily: '$inter',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: '$32',
  paddingBottom: '10%',
  width: '100vw',
});

export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$32',
  textAlign: 'center',
});

export const Section = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
});

export const ButtonContent = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: '$8',
  justifyContent: 'center',
});

export const CallToActions = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: '$16',
  justifyContent: 'center',
});
