/* eslint-disable canonical/filename-match-exported */
import { NotFound } from '@/components/NotFound/NotFound.js';

// Building with NODE_ENV=development can cause next build to fail with:
// Generating static pages (0/1) Error: <Html> should not be imported outside of pages/_document.
const Custom404 = () => {
  return <NotFound />;
};

export default Custom404;
