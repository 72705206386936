import {
  ButtonContent,
  CallToActions,
  Content,
  NotFoundContainer,
  Section,
} from './NotFound.styles.js';
import { StandardArrowRightIcon } from '@/components/Icons/StandardArrowRightIcon.js';
import { PlatformButton } from '@/components/PlatformButton/PlatformButton.js';
import { Body } from '@/components/Typography/Body.js';
import { Heading } from '@/components/Typography/Heading.js';

export const NotFound = () => {
  return (
    <NotFoundContainer>
      <Content>
        <Section>
          <Heading
            as="h2"
            css={{
              marginBottom: '$0',
              marginTop: '$0',
            }}
            variant="h3"
          >
            404
          </Heading>
          <Heading
            as="h1"
            css={{
              '@bp0': {
                fontSize: '$32',
              },
              '@bp2': {
                fontSize: '$58',
              },
              marginBottom: '$0',
              marginTop: '$0',
            }}
            variant="h1"
          >
            Uh oh. This page doesn’t exist.
          </Heading>
        </Section>
        <Section>
          <Body
            css={{
              '@bp0': {
                fontSize: '$16',
              },
              '@bp2': {
                fontSize: '$22',
              },
              fontFamily: '$body',
              fontWeight: 400,
              lineHeight: '$body',
            }}
          >
            Want to learn more about Portfolios by Contra?{' '}
          </Body>
        </Section>
        <Section>
          <CallToActions>
            <PlatformButton
              as="a"
              href="https://contra.com/portfolios"
              rel="noreferrer"
              variant="primary"
            >
              View Portfolios
            </PlatformButton>

            <PlatformButton
              as="a"
              href="https://contra.com/"
              rel="noreferrer"
              variant="secondary"
            >
              <ButtonContent>
                Back to Contra <StandardArrowRightIcon />
              </ButtonContent>
            </PlatformButton>
          </CallToActions>
        </Section>
      </Content>
    </NotFoundContainer>
  );
};
