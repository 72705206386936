import { styled } from '@/stitches/index.js';

export const Heading = styled('span', {
  color: '$defaultFont',
  fontFamily: '$heading',
  fontWeight: '$heading',
  letterSpacing: '$safe1px',
  lineHeight: '$heading',
  marginBottom: '$16',
  marginTop: '$16',
  // eslint-disable-next-line canonical/sort-keys
  '@bp0': {
    fontSize: '$32',
  },
  '@bp2': {
    fontSize: '$64',
  },
  variants: {
    variant: {
      h1: {
        '@bp0': {
          fontSize: '$32',
        },
        '@bp2': {
          fontSize: '$64',
        },
      },
      h2: {
        '@bp0': {
          fontSize: '$24',
        },
        '@bp2': {
          fontSize: '$44',
        },
      },
      h3: {
        '@bp0': {
          fontSize: '$20',
        },
        '@bp2': {
          fontSize: '$40',
        },
      },
      h4: {
        '@bp0': {
          fontSize: '$18',
        },
        '@bp2': {
          fontSize: '$36',
        },
      },
      h5: {
        '@bp0': {
          fontSize: '$16',
        },
        '@bp2': {
          fontSize: '$34',
        },
      },
    },
  },
});
