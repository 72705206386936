import { styled } from '@/stitches/index.js';

export const Body = styled('span', {
  color: '$defaultFont',
  fontFamily: '$body',
  fontSize: '$14',
  fontWeight: '$body',
  letterSpacing: '$safe1px',
  lineHeight: '$body',
});
